import React from "react"
import SEO from "@components/common/SEO"
import StoryCover from "@components/StoryCover"
import { graphql } from "gatsby"
import { renderBlocks } from "@src/build/helpers"
import useGeneralContext from "@components/common/hoc/useGeneralContext"

const Story = ({ data, pageContext }) => {
  const {
    seo,
    title,
    featuredImage,
    ACFStoryGeneral: { storyPageBackgroundColor, imageBlendingMode },
    ACFStory: { storyCover, storyBody },
  } = data.wpStory
  const {
    currentBgColor,
    setCurrentBgColor,
    isHome,
    setIsHome,
  } = useGeneralContext()

  seo.ogType = "article"

  React.useEffect(() => {
    if (
      storyPageBackgroundColor &&
      currentBgColor !== storyPageBackgroundColor
    ) {
      setCurrentBgColor(storyPageBackgroundColor)
    }

    if (isHome) {
      setIsHome(!isHome)
    }
  }, [currentBgColor])

  return (
    <React.Fragment>
      <SEO seo={seo} />
      <StoryCover
        title={title}
        storyCover={storyCover}
        blendMode={imageBlendingMode}
        classNames={"mb-20"}
      />
      {renderBlocks(storyBody)}
    </React.Fragment>
  )
}

export const data = graphql`
  query StoryQuery($id: String!) {
    wpStory(id: { eq: $id }) {
      title
      ACFStoryGeneral {
        storyPageBackgroundColor
        imageBlendingMode
      }
      ACFStory {
        storyCover {
          copyColor
          coverVideoLink {
            vimeoLinkDesktop
            vimeoLinkMobile
          }
          mediaAlignment
          mediaType
          storyCredits {
            role
            name
            link
          }
          coverImageFile {
            altText
            title
            mediaDetails {
              sizes {
                height
                name
                sourceUrl
                width
              }
            }
            mediaItemUrl
          }
        }
        storyBody {
          ... on WpStory_Acfstory_StoryBody_ParagraphBlock {
            alignment
            columns
            copy
            fieldGroupName
          }
          ... on WpStory_Acfstory_StoryBody_SingleImageBlock {
            alignment
            blendMode
            columns
            fieldGroupName
            file {
              altText
              caption
              title
              mediaDetails {
                sizes {
                  height
                  name
                  sourceUrl
                  width
                }
              }
              mediaItemUrl
            }
          }
          ... on WpStory_Acfstory_StoryBody_VideoBlock {
            alignment
            autoplay
            columns
            fieldGroupName
            vimeoLink {
              fieldGroupName
              portraitLink
              landscapeLink
            }
          }
          ... on WpStory_Acfstory_StoryBody_MediaCarouselBlock {
            blendMode
            fieldGroupName
            media {
              mediaType
              image {
                altText
                title
                mediaDetails {
                  sizes {
                    height
                    name
                    sourceUrl
                    width
                  }
                }
                mediaItemUrl
              }
              videoLink {
                fieldGroupName
                landscapeLink
                portraitLink
              }
            }
          }
          ... on WpStory_Acfstory_StoryBody_FeaturedStoryBlock {
            fieldGroupName
            featuredStory {
              ... on WpStory {
                title
                uri
                ACFStory {
                  storyCover {
                    copyColor
                    coverVideoLink {
                      vimeoLinkDesktop
                      vimeoLinkMobile
                    }
                    excerpt
                    mediaAlignment
                    mediaType
                    coverImageFile {
                      altText
                      title
                      mediaDetails {
                        sizes {
                          height
                          name
                          sourceUrl
                          width
                        }
                      }
                      mediaItemUrl
                    }
                  }
                }
                ACFStoryGeneral {
                  storyPageBackgroundColor
                  imageBlendingMode
                }
              }
            }
          }
        }
      }
      seo {
        ...seoFields
      }
    }
  }
`

export default Story
